import { graphql, useStaticQuery } from 'gatsby';

import { AllDirectusStepsItem, StepsItem } from './steps-items.interface';

export default (): StepsItem[] => {
  const { allDirectusStepsItem } = useStaticQuery<AllDirectusStepsItem>(graphql`
    query {
      allDirectusStepsItem(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            heading
            subtitle
            content
            icon
            page {
              directusId
              slug
              title
              description
              canonical
            }
          }
        }
      }
    }
  `);

  return allDirectusStepsItem.edges;
};
