// extracted by mini-css-extract-plugin
export var steps = "StepsItems--steps--NBHwZ";
export var steps__content = "StepsItems--steps__content--lP+TI";
export var steps__heading = "StepsItems--steps__heading--guTgv";
export var steps__icon = "StepsItems--steps__icon--cUzLv";
export var steps__iconSubtitle = "StepsItems--steps__iconSubtitle--8fASg";
export var steps__item = "StepsItems--steps__item--IY--d";
export var steps__item___checkout = "StepsItems--steps__item___checkout--TWRw3";
export var steps__item___companyName = "StepsItems--steps__item___companyName--kTxRf";
export var steps__item___enterCompanyDetails = "StepsItems--steps__item___enterCompanyDetails--itenk";
export var steps__item___package = "StepsItems--steps__item___package--Ep05e";
export var steps__subtitle = "StepsItems--steps__subtitle--wGvQ3";
export var steps__title = "StepsItems--steps__title--5bLyX";
export var tkMyriadProSemiCondensed = "StepsItems--tk-myriad-pro-semi-condensed--WdAFz";