import React from 'react';
import camelcase from 'camelcase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HowToRichSnippet from 'components/common/rich-snippets/how-to/HowTo';
import Markdown from 'components/common/markdown/Markdown';
import stepsItemsHook from 'hooks/steps-items/steps-items.hook';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome/solid';

import { Props } from './StepsItems.interface';

import {
  steps as stepsClass,
  steps__item,
  steps__title,
  steps__icon,
  steps__iconSubtitle,
  steps__heading,
  steps__subtitle,
  steps__content,
  steps__item___companyName,
  steps__item___package,
  steps__item___checkout,
  steps__item___enterCompanyDetails,
} from './StepsItems.scss';

const getStepsItemClass = (heading: string) => {
  switch (camelcase(heading.substring(3))) {
    case 'companyName':
      return steps__item___companyName;
    case 'package':
      return steps__item___package;
    case 'checkout':
      return steps__item___checkout;
    case 'enterCompanyDetails':
      return steps__item___enterCompanyDetails;
    default:
      return '';
  }
};

export default ({ slug }: Props): JSX.Element => {
  const items = stepsItemsHook().filter(({ node }) => node.page.slug === slug);
  const { title, description } = items[0].node.page;
  const steps = items.map(({ node }) => ({
    text: node.content,
    name: node.heading,
    url: node.page.canonical,
  }));

  return (
    <>
      <div className={stepsClass}>
        {items.map(({ node }) => (
          <div key={`steps-item-${node.directusId}`} className={`${steps__item} ${getStepsItemClass(node.heading)}`}>
            <div className={steps__title}>
              <span className={`${steps__icon} ${node.subtitle ? steps__iconSubtitle : ''}`}>
                <FontAwesomeIcon icon={getSolidFontAwesomeIcon(node.icon)} />
              </span>

              <h2 className={steps__heading}>
                {node.heading}
                <span className={steps__subtitle}> {node.subtitle}</span>
              </h2>
            </div>

            <Markdown
              className={steps__content}
              source={node.content}
              container
              listItemIcon={<FontAwesomeIcon icon={getSolidFontAwesomeIcon('faAngleRight')} />}
            />
          </div>
        ))}
      </div>
      <HowToRichSnippet title={title} description={description} steps={steps} />
    </>
  );
};
