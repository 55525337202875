import React from 'react';
import { Helmet } from 'react-helmet';

import { Props } from './HowTo.interface';

const HowToSnippet = ({ title, description, steps }: Props): JSX.Element => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'HowTo',
        name: title,
        description,
        step: [
          steps.map(({ name, text, url }) => ({
            '@type': 'HowToStep',
            name,
            text,
            url,
          })),
        ],
      })}
    </script>
  </Helmet>
);

export default HowToSnippet;
