import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import CallToAction from 'components/directus/call-to-action/CallToAction';
import MainBanner from 'components/directus/main-banner/MainBanner';
import PageTitle from 'components/directus/page-title/PageTitle';
import StepsItems from 'components/directus/steps-items/StepsItems';
import CustomerReviews from 'components/directus/customer-reviews/CustomerReviews';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { PageProps } from 'utils/gatsby.interface';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
  const slug = 'steps-to-forming-a-company';
  const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);

  return (
    <>
      <Helmet>
        <title>{node.title}</title>
        <meta name="title" content={node.title} />
        <meta name="description" content={node.description} />
        <meta name="robots" content={node.robots} />
        <link rel="canonical" href={node.canonical} />
      </Helmet>

      <MainBanner slug={slug} />

      <Wrapper>
        <Section bottom={0}>
          <PageTitle slug={slug} />
        </Section>
        <Section>
          <StepsItems slug={slug} />
        </Section>
        <Section>
          <CustomerReviews />
        </Section>
        <Section>
          <CallToAction slug="company-formation" />
        </Section>
      </Wrapper>
    </>
  );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "steps-to-forming-a-company" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
